import { Router } from '@angular/router';
import { SideNavService } from './../../../core/services/side-nav/side-nav.service';
import { Component, EventEmitter, Input, OnInit } from '@angular/core';
import { collapseOnLeaveAnimation, expandOnEnterAnimation } from 'angular-animations';
import { NavigationBarModel } from 'src/app/core/models/navBar/navBar';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { MatDrawerMode } from '@angular/material/sidenav';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-side-nav',
  templateUrl: './side-nav.component.html',
  styleUrls: ['./side-nav.component.scss'],
  animations: [expandOnEnterAnimation(), collapseOnLeaveAnimation()]
})
export class SideNavComponent implements OnInit {
  public isVisible: boolean = true;
  public openSegments: boolean = false;
  public isHovered: boolean = false;
  public isExpanded: boolean = false;
  public openContracting: boolean = false;
  public isHoveredContracting: boolean = false;
  public isExpandedContracting: boolean = false;

  public linkList: NavigationBarModel[] = [];
  public isMobileView: boolean = false; // Nueva propiedad

  public getDenariusId(): string {
    return `/denarius-finance/${environment.denariusId}`;
  }

  constructor(
    public nav: SideNavService,
    private router: Router,
    private breakPointObserver: BreakpointObserver
    ) {
    this.linkList = [
      {
        title: "Equipo",
        url: "/equipo",
        deploy: false,
        matIcon: "supervised_user_circle",
      },
      {
        title: "Denarius Finance",
        url: this.getDenariusId(),
        deploy: false,
        matIcon: "supervised_user_circle",
      },
      {
      title: "Clientes",
      deploy: true,
      linkDeployed: false,
      matIcon: "account_circle",
      subtitles: [
        {
        title: "Empresas",
        url: "/empresas"
        },
        {
          title: "Particulares",
          url: "/particulares"
        },
      ]
      },
      {
        title: "Contratación",
        deploy: true,
        linkDeployed: false,
        matIcon: "playlist_add_circle",
        subtitles: [
          {
            title: "Planes",
            url: "/contratacion/planes"
          },
          {
            title: "Asesoramiento",
            url: "/contratacion/asesoramiento"
          },
          {
            title: "Financiación",
            url: "/contratacion/renting"
          },
          {
            title: "Seguros",
            url: "/contratacion/seguros"
          },
          {
            title: "Odontólogos",
            url: "/contratacion/odontologos"
          },
          {
            title: "Préstamos",
            url: "/contratacion/prestamos"
          }
        ]
      },
      {
        title: "Contacto",
        deploy: true,
        linkDeployed: false,
        matIcon: "mark_as_unread",
        subtitles: [
          {
            title: "Empleo",
            url: "/contacto/empleo"
          },
          {
            title: "Contacto",
            url: "/contacto/contacto"
          },
          {
            title: "Llave en mano",
            url: "/contacto/llave-en-mano"
          }
        ]
      },
      {
        title: "Impulsamos tu clínica",
        url: "/impulsamos-tu-clinica",
        deploy: false,
        matIcon: "masks",
      },
/*       {
        title: "Transferencias",
        url: "/transferencias",
        deploy: false,
        matIcon: "change_circle",
      }, */
      {
        title: "Facturación",
        url: "/facturas",
        deploy: false,
        matIcon: "receipt_long",
      },
    ];
  }

  @Input()
  breakpointObserver!: BreakpointObserver;

  mode: 'over' | 'side' = 'side'

  ngOnInit(): void {
    this.breakPointObserver
    .observe(['(max-width: 900px)'])
      .subscribe((state: BreakpointState) => {
        if (state.matches) {
          this.mode = 'over';
          this.isMobileView = true;
          this.hideNav();
        } else {
          this.mode = 'side';
          this.isMobileView = false;
          this.showNav();
        }
      });
  }

  toggleConfig(item: NavigationBarModel): void {
    if(item.linkDeployed){
      item.linkDeployed = !item.linkDeployed;
    } else {
      this.linkList.forEach(elem => {
        elem.linkDeployed = false;
      });
      item.linkDeployed = true;
    }
  }

  showNav() {
    this.isVisible = true;
  }

  hideNav() {
    this.isVisible = false;
  }

  navigateAndClose() {
    if (this.isMobileView) {
      this.hideNav();
    }
  }
}
