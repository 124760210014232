<mat-stepper orientation="vertical" [linear]="true" #stepper (selectionChange)="stepChange($event)">

  <mat-step [completed]="validOperationData">
    <ng-template matStepLabel>Datos de la operación</ng-template>
    <div class="step-content">
      <app-operation-data [transferType]="'national'"
        (operationDataEvent)="receiveOperationData($event)"></app-operation-data>
    </div>

  </mat-step>
  <mat-step [completed]="validBeneficiary">
    <ng-template matStepLabel>Datos del beneficiario</ng-template>
    <div class="step-content">
      <app-beneficiary-data *ngIf="validOperationData" [originAccount]="originAccount" [transferType]="'national'"
        (beneficiaryDataEvent)="receiveBeneficiaryData($event)"></app-beneficiary-data>
      <button class="gold-button margin" [disabled]="!validBeneficiary"
        (click)="onSubmit()">ENVIAR</button>
    </div>
  </mat-step>

  <mat-step>
    <ng-template matStepLabel>Confirmar transferencia</ng-template>
    <div class="step-content">
      @if (transferData) {
           <app-transfer-summary [transferData]="transferData" [ibanBeneficiary]="ibanBeneficiary" (resetStepperEvent)="resetTransfers()"></app-transfer-summary>
      }
   
    </div>
  </mat-step>

</mat-stepper>