import { FormGroup, FormBuilder } from '@angular/forms';
import { Component } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatTableModule } from '@angular/material/table';
/* import { UserService } from 'src/app/core/services/userArea/user.service'; */
import { AccountsList } from 'src/app/core/models/user/userData';
import { FilterParams } from 'src/app/core/models/filters/filters.model';
import { AccountMovements } from 'src/app/core/models/accounts/account-movements';
import { forkJoin } from 'rxjs';
import { map } from 'rxjs/operators';
import { UserService } from 'src/app/core/services/userArea/user.service';
import { downloadFile } from 'src/app/core/extensions/download-files';
import { environment } from 'src/environments/environment';


@Component({
  selector: 'app-consult-transfers',
  templateUrl: './consult-transfers.component.html',
  styleUrls: ['./consult-transfers.component.scss'],
})
export class ConsultTransfersComponent {
  public transfersForm!: FormGroup;
  public userAccounts!: AccountsList[];

  public displayedColumns: string[] = [
    /*     'account', */
    'date',
    'description',
    'amount',
    'download' /* 'type' */,
  ];
  public columnsToDisplayWithExpand = [...this.displayedColumns, 'expand'];


  public movementsData: AccountMovements[] = [];
  public accumulatedData: AccountMovements[] = [];
  public dataSource: any = [];
  public accountId: any; // ID de la cuenta seleccionada
  public loader: boolean = false;


  // for Filters
  public _selectedAccountId?: string;
  public allParams: FilterParams = {};

  //For pagination
  public currentPage: number = 1;
  public offset: number = 10;
  public hideNextButton: boolean = false;

  public showNo!: boolean;
  public userId = environment.denariusId;
/* public userId = localStorage.getItem('userId')! */

  constructor(private formBuilder: FormBuilder, private userService: UserService, private downloadReceipt: UserService) { }

  ngOnInit() {
    this.loadInitialAccounts();
    this.setupForm();
  }

  loadInitialAccounts() {
    const accountsObservable = this.userService.getAccounts(this.userId)
      .pipe(map(res => res.filter(item => item.state === 'ACTIVATED')));

    forkJoin({ accounts: accountsObservable })
      .subscribe({
        next: ({ accounts }) => {
          this.userAccounts = accounts;
          if (accounts.length > 0) {
            this.selectedAccount(accounts[0].id); // Selección automática de la primera cuenta 
          }
        },
        error: (err) => {
          console.error('No se ha cargado', err);
          this.loader = false;
        }
      });
  }

  //para cuando cambias de cuenta hay que resetear el componente
  resetComponent() {
    this.accumulatedData = []; // Limpiar los datos acumulados
    this.dataSource = [];
    this.currentPage = 1; // Reiniciar la paginación
  }

  getTransfers() {
    this.loader = true;
      this.allParams.page = this.currentPage;
      this.allParams.offset = this.offset

    this.getMovements(this.accountId, this.allParams)

    if (this.movementsData.length !== this.offset) {
      this.showNo = false;
    }

  }


  setupForm() {
    this.transfersForm = this.formBuilder.group({
      startdate: [''],
      enddate: [''],
      account: [''],
    });
  }

  selectedAccount(newAccountId: any) {
    if (this.accountId !== newAccountId) {
      this.accountId = newAccountId;
      this.resetComponent(); // Reiniciar componentes
      this.getTransfers(); // Cargar nuevos datos
    }
  }

  moreMovements() {
    this.currentPage++
    this.allParams.page = this.currentPage;
    this.allParams.offset = this.offset
    this.getTransfers()
  }

  receiveFilters(item: any) {
    this.allParams = item;
    this.resetComponent();
    this.getTransfers()
  }

  getMovements(accountId: any, params: any) {
    this.userService.getMovements(accountId, params).subscribe({
      next: (res) => {
        this.movementsData = res;
        this.accumulatedData.push(...this.movementsData);
        this.dataSource = new MatTableDataSource(this.accumulatedData);

        this.hideNextButton = this.movementsData.length < this.offset;
        this.loader = false;
      },
      error: (err) => {
        console.error('Error obteniendo los movimientos', err);
        this.loader = false;
      }
    });
  }

  downloadMovement(movementId: any, operationNumber: any) {
    this.downloadReceipt.downloadMovement(this.userId, this.accountId, movementId, operationNumber).subscribe(res => {
      const fileMovement = res;
      downloadFile(fileMovement, 'transferencia')
    })
  }

}
