<form [formGroup]="accountForm">
  <mat-form-field appearance="fill" class="accounts">
    <mat-select formControlName="account" (selectionChange)="submitAccount()">
      <ng-container *ngFor="let item of accountData">
      <mat-option *ngIf="item.state === 'ACTIVATED'" [value]="item.id">
        {{item.iban}}
      </mat-option>
      </ng-container>
    </mat-select>
    <mat-label>Cuentas</mat-label>
  </mat-form-field>
</form>
