import { Component, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { forkJoin } from 'rxjs';
import { Plans } from 'src/app/core/models/plans/plans';
import { UserData } from 'src/app/core/models/user/userData';
import { PlansService } from 'src/app/core/services/plans/plans.service';
import { UserService } from 'src/app/core/services/user/user.service';
import { Location } from '@angular/common';
import { BusinessService } from 'src/app/core/services/user/business.service';
@Component({
  selector: 'app-products',
  templateUrl: './products.component.html',
  styleUrls: ['./products.component.scss'],
})
export class ProductsComponent implements OnInit {
  public segmentPlans!: Plans[];
  public initialPlan!: Plans;
  public planSelected: FormControl = new FormControl('');
  public isVisibleChangePlan: boolean = false;
  public productSelected: FormControl = new FormControl('');
  public allProducts!: any[];
  public userPlan!: any;
  public url!: string;
  public userId!: string;
  public loader!: boolean;
  public msgNoPlan!: string;
  public plans: any;
  public disabledChange!: boolean;
  _userData: any;

/*   @Input() userData!: UserData; */

  @Input() set userData(val: any) {
    if (!val) {
      return
    }
    this._userData = val
    this.userId = this._userData.id;
  }

  constructor(
    private userService: UserService,
    private companyService: BusinessService,
    private router: Router,
    private PlanService: PlansService,
    private location: Location
  ) {
    this.allProducts = [
      {
        value: 'Seguros',
      },
      {
        value: 'Leasing y Renting',
      },
      {
        value: 'Préstamos',
      },
      {
        value: 'Préstamos',
      },
      {
        value: 'Hipotecas',
      },
      {
        value: 'Asesoramiento',
      },
    ];
  }


  ngOnInit(): void {
    this.loader = true;
/*     this.userId = this.userData.id!; */
    this.url = this.location.path();
    if(this.url.includes('empresas')){
      this.getBusinessAndPlans(this.userId)
    } else if(this.url.includes('particulares')){
      this.getUserAndPlans(this.userId)
    } else if(this.url.includes('denarius-finance')){
      this.getBusinessAndPlans(this.userId)
    }

  }

  onSubmit() {}
  redirectToPlans() {
    this.router.navigate(['/contratacion/planes']);
  }


  /*   getPlans(){
    this.plansService.getPlansList().subscribe( 
      res => {
        const allPlans: Plans[] = res
        console.log("resAll", allPlans)
        this.segmentPlans = []
        this.segmentPlans = allPlans.filter((element: { segmentId: string | undefined; }) => element.segmentId == this.userData.segmentId)
          console.log("segmentPLans", this.segmentPlans)
        
      })
  } */

  getUserAndPlans(userId: string) {
    forkJoin({
      user: this.userService.getUser(userId),
      plansList: this.PlanService.getPlansList()
    }).subscribe({
      next: ({ user, plansList }) => {
        this.userData = user;
        const productId = user.productId;

        this.userPlan = plansList.find((item) => item.id === productId);

        const hasPlansBusiness = plansList.filter(item => item.segmentId === 'BUSINESS' );
        if(hasPlansBusiness.length <= 1) {
          this.disabledChange = true;
        }

        if (this.userPlan) {
          this.loader = false;
        } else {
          this.loader = false;
          this.msgNoPlan = "No hay plan contratado."
        }
      },
      error: (err) => {
        console.error('Error fetching data:', err);
      }
    });
  }

  getBusinessAndPlans(userId: string) {
    forkJoin({
      user: this.companyService.getCompany(userId),
      plansList: this.PlanService.getPlansList()
    }).subscribe({
      next: ({ user, plansList }) => {
        this.userData = user;
        const productId = user.productId;

        this.userPlan = plansList.find((item) => item.id === productId);

        const hasPlansIndividual = plansList.filter(item => item.segmentId === 'INDIVIDUAL' );
        if(hasPlansIndividual.length <= 1) {
          this.disabledChange = true;
        }

        console.log(plansList)
        
        if (this.userPlan) {
          this.loader = false;
        } else {
          this.loader = false;
          this.msgNoPlan = "No hay plan contratado."
        }
      },
      error: (err) => {
        console.error('Error fetching data:', err);
      }

    })

  }
  
  toogleChangePlan() {
    if (!this.isVisibleChangePlan) {
      this.isVisibleChangePlan = true;
    } else {
      this.isVisibleChangePlan = false;
    }
  }
}
