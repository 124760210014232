import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { tap, catchError, throwError } from 'rxjs';
import { Segment } from 'src/app/core/models/plans/plans';
import { Transfers } from 'src/app/core/models/transfers/transfers';
import { TransfersService } from 'src/app/core/services/transfers/transfers.service';
import { BusinessService } from 'src/app/core/services/user/business.service';
import { PhoneValidationComponent } from 'src/app/shared/components/phone-validation/phone-validation.component';

@Component({
  selector: 'app-transfer-summary',
  templateUrl: './transfer-summary.component.html',
  styleUrls: ['./transfer-summary.component.scss'],
})
export class TransferSummaryComponent {

  public isLoading!: boolean;
  public showAlert: boolean = false;
  public alertMessage!: string;
  public alertStatus!: string;
  public role!: string;
  public confirmTransfer: boolean = false;
  public bodyErrorSca: any;
  public operationKeys: any;
  public userId = localStorage.getItem('userId')!;

  @Input() transferData!: Transfers
  @Input() ibanBeneficiary?: string
  @Output() resetStepperEvent = new EventEmitter<boolean>();
  @Input() noBeneficiary!: boolean;
  constructor(public dialog: MatDialog, private trasfersService: TransfersService, private transfersService: TransfersService,private businessService: BusinessService) { }
  ngOnInit() {
    if (this.trasfersService.transferData) {
      this.transferData = this.trasfersService.transferData;
      console.log("esto es el servicio",this.transferData);
    } else {
      console.error("Transfer data is missing");
    }
  }
  

  refreshPage(): void {
    window.location.reload()
  }

  postTransfer() {
    this.isLoading = true
    console.log(this.transferData)
    this.transfersService.postTransfer(this.transferData).pipe(
      tap((res) => {
        this.operationKeys = {
          id: res.opId
        }
        this.verifyTransfer()
      }),
      catchError((err) => {
        if (err.sca) {
          this.bodyErrorSca = err.sca;
          this.openVerification();
        }
        else {
          this.alertStatus = 'ko';
          this.showAlert = true
          this.alertMessage = 'Hubo un error en tu transferencia. Por favor, inténtalo de nuevo';
        }
        return throwError(() => err);
      })
    ).subscribe();
  }

  openVerification() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.data = {
      sca: this.bodyErrorSca,
    }

    const dialogRef = this.dialog.open(PhoneValidationComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(
      data => {
        if (data) {
          this.operationKeys = data
          this.verifyTransfer()
        }

      }
    );
  }

  verifyTransfer() {
    this.trasfersService.confirmSca(this.operationKeys).subscribe({
      complete: () => {
        this.alertMessage = 'Se ha realizado correctamente la transferencia';
        this.alertStatus = 'ok';
        this.showAlert = true
      },
      error: (err) => {
        this.alertStatus = 'ko';
        this.showAlert = true
        if (err.message == 'The code is not valid') {
          this.alertMessage = 'Has introducido un código incorrecto. Inténtalo de nuevo.';
        } else {
          this.alertMessage = 'Hubo un error en tu transferencia. Por favor, inténtalo de nuevo';
        }
      },
    })
  }

  resetStepper() {
    this.resetStepperEvent.emit(true)
    this.alertStatus = '';
    this.showAlert = false;
    this.isLoading = false;
  }

}
