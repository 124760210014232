<div class="input-type">
    <app-search-bar [placeholder]="placeholder" (searchEvent)="onSearch($event)"></app-search-bar>
    <img src="../../../../../assets/img/svg/Search.svg" alt="">
  </div>
  <div class="tableMaterial" [@enterRight]>
      <table *ngIf="!loader" mat-table [dataSource]="sortedData" class="table-container" matSort (matSortChange)="sortData($event)">
          <ng-container matColumnDef="codeOperation">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>Operación</th>
              <td mat-cell *matCellDef="let element" class="pointer gold"> 
                <p>{{element.codeOperation}}</p> 
              </td>
          </ng-container>
          <ng-container matColumnDef="date">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Fecha </th>
              <td mat-cell *matCellDef="let element" class="pointer"> 
                <p>{{element.date | date: "dd/MM/yyyy"}}</p> 
              </td>
          </ng-container>
          <ng-container matColumnDef="identifier">
              <th mat-header-cell *matHeaderCellDef> CIF/NIF </th>
              <td mat-cell *matCellDef="let element" class="pointer"> 
                <p>{{element.customer.identifier}}</p> 
              </td>
          </ng-container>
          <ng-container matColumnDef="name">
              <th mat-header-cell *matHeaderCellDef> Empresa/Cliente </th>
              <td mat-cell *matCellDef="let element" class="pointer"> 
                <p class="companyName">{{element.customer.name}}</p> 
              </td>
          </ng-container>
          <ng-container matColumnDef="clientType">
              <th mat-header-cell *matHeaderCellDef> Tipo </th>
              <td mat-cell *matCellDef="let element" class="pointer">
                <p>{{ element.type }}</p>  
              </td>
          </ng-container>
          <ng-container matColumnDef="totalAmount">
              <th mat-header-cell *matHeaderCellDef> Importe </th>
              <td mat-cell *matCellDef="let element" class="pointer"> 
                <p>{{element.totalAmount | currency : "€" : "symbol"}}</p> 
              </td>
          </ng-container>
          <ng-container matColumnDef="state">
              <th mat-header-cell *matHeaderCellDef> Estado </th>
              <td mat-cell *matCellDef="let element" class="pointer"> 
                <p [ngClass]="getStatusClass(element.state)">{{translateState(element.state)}}</p> 
              </td>
          </ng-container>
  
          <tr 
            mat-header-row *matHeaderRowDef="displayedColumns">
          </tr>
          <tr 
            mat-row *matRowDef="let element; columns: displayedColumns;" 
            (click)="getIdentifier(element.customer.identifier, element.codeOperation)">
          </tr>
      </table>
  
      <div class="skeleton-container" *ngIf="loader">
          <ngx-skeleton-loader count="{{offset - 1}}" appearance="line" [theme]="{ height: '36px' }">
          </ngx-skeleton-loader>
      </div>
      <mat-paginator #paginator
      class="demo-paginator"
      [length]="length"
      [pageSize]="pageSize"
      [pageIndex]="pageIndex"
      [pageSizeOptions]="pageSizeOptions"
      [showFirstLastButtons]="showFirstLastButtons"
      (page)="handlePageEvent($event)">
  </mat-paginator>
  </div>
  