import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class InstallmentService {
  private installment: any;

  setInstallment(data: any): void {
    this.installment = data;
  }

  getInstallment(): any {
    return this.installment;
  }
}
