<mat-stepper orientation="vertical" [linear]="true" #stepper class="own-stepper">

  <mat-step [completed]="validOperationData">
    <ng-template matStepLabel>Datos de la operación</ng-template>
    <app-operation-data [transferType]="'ownAccounts'" (operationDataEvent)="receiveOperationData($event)"></app-operation-data>
    <button class="gold-button" matRipple style="width: 170px;"  [disabled]="!validOperationData" (click)="onSubmit()">ENVIAR</button>
  </mat-step>
<!--   <mat-step [completed]="typeIsValid">
    <ng-template matStepLabel i18n="@@type.transfers">Tipo de transferencia</ng-template>
    <app-transfer-type (emitForm)="recieveTypeForm($event)"></app-transfer-type>
  </mat-step>  -->
  <mat-step>
    <ng-template matStepLabel>Confirmar transferencia</ng-template>
    @if (transferData) {
      <app-transfer-summary [noBeneficiary]="true" [transferData]="transferData" [ibanBeneficiary]="ibanBeneficiary" (resetStepperEvent)="resetTransfers()"></app-transfer-summary>
 }
  </mat-step>
</mat-stepper>