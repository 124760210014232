import { style } from '@angular/animations';
import { toPanSecretResume } from 'src/app/core/extensions/functions';
import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, FormGroupDirective, Validators } from '@angular/forms';
import { allRegex } from 'src/app/core/extensions/regex';
import { UserService } from 'src/app/core/services/userArea/user.service';
import { catchError, of, tap, throwError } from 'rxjs';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { PhoneValidationComponent } from 'src/app/shared/components/phone-validation/phone-validation.component';
import { TransfersService } from 'src/app/core/services/transfers/transfers.service';
import { ConfirmationModalComponent } from 'src/app/shared/components/confirmation-modal/confirmation-modal.component';
import { FavAccount } from 'src/app/core/models/transfers/transfers';
@Component({
  selector: 'app-beneficiary-data',
  templateUrl: './beneficiary-data.component.html',
  styleUrls: ['./beneficiary-data.component.scss'],
})
export class BeneficiaryDataComponent implements OnInit {
  form!: FormGroup;
  public alias: boolean = false;
  public disabled: boolean = false
  toPanSecretResume = toPanSecretResume
  public esIban: any[] = []
  public exIban: any[] = []
  public _originAccount!: string;
  public _idAccount!: string;
  public favAccounts!: any[]
  public favAccCreated!: boolean;
  public alertMessage!: string;
  public alertStatus!: string
  public isAlertOpen: boolean = false;

  @Output() beneficiaryDataEvent = new EventEmitter<FormGroup>();
  @Input() transferType?: string;
  @Input() set originAccount(val: string) {
    this._originAccount = val
    this.getFavAccounts()
  };
  @ViewChild(FormGroupDirective) formDirective!: FormGroupDirective;



  constructor(private formBuilder: FormBuilder, private transfersService: TransfersService, private userService: UserService, private dialog: MatDialog) {



  }
  ngOnInit() {
    this.form = this.formBuilder.group({
      beneficiaryName: ['', [Validators.required]],
      beneficiaryAccount: ['', [Validators.required]],
      favAccount: [],
      //Inputs para enseñar un valor autorellenado porque sino no se setea el valor ene l form
      beneficiaryAccountDis: [],
      beneficiaryNameDis: []
    });

    if (this.transferType === 'international') {
      /* this.form.addControl('otherAccount', new FormControl('', Validators.required)) */
      this.form.addControl('swiftBIC', new FormControl('', Validators.required))
      this.form.get('beneficiaryAccount')?.addValidators(Validators.pattern(allRegex.regexInternational))
    } else if (this.transferType === 'ownAccounts') {
      this.form.removeControl('beneficiaryName');
    } else if (this.transferType === 'national') {
      this.form.get('beneficiaryAccount')?.addValidators(Validators.pattern(allRegex.regexEspIban))
    }

    this.form.valueChanges.subscribe((val) => {
      this.beneficiaryDataEvent.emit(this.form);
    });
  }

  getFavAccounts() {
    this.userService.getFavAccounts(this._originAccount).subscribe(res => {
      this.favAccounts = res
      /*if (this.favAccounts?.length != 0) {
       this.filterAccounts() 
     }*/

      if (this.favAccCreated) {
        const favAccountAdded = this.favAccounts.find(item => item.iban == this.form.get('beneficiaryAccount')?.value)
        this.form.get('favAccount')?.setValue(favAccountAdded)
        this.setInputs()
      }
    })
  }

  handleAlertClosed(isClosed: boolean) {
    this.isAlertOpen = !isClosed;
  }


  createFavAccount() {
    const data = {
      alias: this.form.get('alias')?.value,
      account: this.form.get('beneficiaryAccount')?.value,
      tokenType: "CEXT"
    }
    this.userService.createFavAccount(this._originAccount, data).subscribe({
      next: (value) => {
      },
      error: (err) => {
        let sca: any
        if (err.sca) {
          sca = err.sca

          const dialogConfig = new MatDialogConfig();
          dialogConfig.disableClose = true;
          dialogConfig.autoFocus = true;
          dialogConfig.data = {
            sca: sca,
            bodyData: data,
          }

          const dialogRef = this.dialog.open(PhoneValidationComponent, dialogConfig);
          dialogRef.afterClosed().subscribe(data => {
            const scaPath = data.endpoint
            /* const scaSplit = scaPath.split("=");
            const newPath = `${scaSplit[0]}${data.sca}` */
            this.userService.confirmInst(scaPath).subscribe({
              next: (res) => {
                this.favAccCreated = true;
                this.isAlertOpen = true;
                this.alertMessage = 'Se ha añadido correctamente la cuenta a favoritos.',
                  this.alertStatus = 'ok'
                this.hideAlias()
                this.getFavAccounts()

              },
              error: (err) => {
                if (err.api_code === '') {
                  this.alertMessage = 'Demasiados intentos de autenticación fallidos consecutivos'
                } else if (err.api_code === 'ES06010') {
                  this.alertMessage = 'Código incorrecto'
                }else {
                  this.alertMessage = 'Esta cuenta ya está guardada'
                  
                }
                this.isAlertOpen = true;
                this.alertStatus = 'ko'


              }
            })
          }

          );
        }
        if (err.api_code == "ES00708") {
          /* this.favAccCreated = false; */
          this.isAlertOpen = true;
          this.alertMessage = 'Esta cuenta ya está guardada',
            this.alertStatus = 'ko'
        } else if (err.api_code == 'ES00101') {
          this.isAlertOpen = true;
          this.alertMessage = 'Revisa que el IBAN sea válido',
            this.alertStatus = 'ko'
        }
        return throwError(() => err);

      }

    })
  }

  deleteFavAccounts(account: FavAccount) {
    this.userService.deleteFavAccount(this._originAccount, account.token!).pipe(
      tap(() => {
        this.isAlertOpen = true;
        this.alertMessage = 'Cuenta eliminada correctamente',
          this.alertStatus = 'ok'
        this.cancel()
        this.getFavAccounts()
      }),
      catchError((error) => {
        if (error.code === 400) {
        }
        return of(null);
      })
    ).subscribe()

  }

  public openModal(account: FavAccount) {
    if (account) {
      this.dialog.open(ConfirmationModalComponent, {
        disableClose: true,
        data: {
          modalMessage: '¿Desea eliminar esta cuenta de favoritos?',
          modalOption: 'Eliminar',
          action: () => {
            this.deleteFavAccounts(account);
          }
        }
      });
    }
  }


  showAlias() {
    this.alias = true
    this.form.addControl('alias', new FormControl('', [Validators.required, Validators.pattern(allRegex.regexNoSpaces)]),)
  }
  hideAlias() {
    this.alias = false
    this.form.removeControl('alias')
  }


  setInputs() {
    const favAccount = this.form.get('favAccount')?.value
    this.form.get('beneficiaryAccountDis')?.setValue(favAccount.iban)
    this.form.get('beneficiaryAccount')?.setValue(favAccount.iban)

    this.form.get('beneficiaryNameDis')?.setValue(favAccount.alias)
    this.form.get('beneficiaryName')?.setValue(favAccount.alias)

    this.form.get('beneficiaryAccountDis')?.disable()
    this.form.get('beneficiaryNameDis')?.disable()
    this.beneficiaryDataEvent.emit(this.form);
    this.disabled = true

  }

  cancel() {

    this.form.get('beneficiaryAccount')?.enable()
    this.form.get('beneficiaryName')?.enable()
    this.disabled = false
    this.formDirective.resetForm()
  }

  filterAccounts() {
    this.favAccounts.filter(element => {
      if (element.iban.substring(0, 2) == 'ES') {
        this.esIban.push(element)
      } else {
        this.exIban.push(element)

      }
    })
  }



}
