import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MatTableDataSource } from '@angular/material/table';
import { PageEvent } from '@angular/material/paginator';
import { AddressData } from 'src/app/core/models/financing/userFinancingData';
import { stateFinancing } from 'src/app/core/extensions/stateFinancing.extension';
import { InstallmentService } from 'src/app/core/services/financing/installment.service';

@Component({
  selector: 'app-amortizacion',
  templateUrl: './amortizacion.component.html',
  styleUrls: ['./amortizacion.component.scss'],
})
export class AmortizacionComponent implements OnInit {
  length!: number;
  pageSize = 10;
  pageIndex = 0;
  pageSizeOptions = [10, 20, 30];
  hidePageSize = false;
  showPageSizeOptions = true;
  showFirstLastButtons = true;
  disabled = false;
  pageEvent!: PageEvent;
  loader: boolean = false;
  public offset: number = 10;
  details: any;
  operation: any; 
  customerDetails?: AddressData;
  shippingAddress?: AddressData;
  stateFinancing = stateFinancing;
  @Input() userData!: any;

  customerDataSource = new MatTableDataSource<any>([]); 
  
  public displayedColumns = [
    'Cuota',
    'Fecha cobro',
    'Principal',
    'Intereses',
    'Mensualidad',
    'Tasas',
    'R.A pagar',
    'Total a pagar',
    'Estado',
  ];
  
  public operations: any[] = [];
  dataSource = new MatTableDataSource<any>([]);
  panelOpenState = false;
  showAmortization: boolean = true;
  showDocuments: boolean = false;

  constructor(private router: Router, private installmentService: InstallmentService) {}

  ngOnInit(): void {

    this.operation = this.userData;
    if (this.operation) {
      this.loadOperationData();
    } else {
      this.router.navigate(['/renting']);
    }
  }

  loadOperationData() {

    if (this.operation && this.operation.installments && this.operation.installments.length) {
      this.prepareDetails(this.operation);
    }
  }

  prepareDetails(operation: any) {
    const currentDate = new Date();
    const nextPayment = operation.installments.find((period: any) => {
      const paymentDate = new Date(period.date);
      return period.state === 'PENDING' && paymentDate > currentDate;
    });
  
    if (!nextPayment) {
      this.details = [
        { label: 'Estado', value: operation.state || 'Desconocido' },
        { label: 'Importe', value: operation.totalAmount || 'N/A' },
        { label: 'Plazo', value: operation.monthsTerm ? `${operation.monthsTerm} meses` : 'N/A' },
        { label: 'PR PAGO', value: 'Pendiente de definir' },
        { label: 'Fecha pago', value: 'Pendiente de definir' },
        { label: 'T.interés', value: 'Pendiente de definir' },
      ];
    } else {
      this.details = [
        { label: 'Estado', value: operation.state || 'Desconocido' },
        { label: 'Importe', value: operation.totalAmount ? `${operation.totalAmount} €` : 'N/A' },
        { label: 'Plazo', value: operation.monthsTerm ? `${operation.monthsTerm} meses` : 'N/A' },
        { label: 'PR PAGO', value: nextPayment.payment ? `${nextPayment.payment.toFixed(2)} €` : 'N/A' },
        { label: 'Fecha pago', value: nextPayment.date || 'N/A' },
        { label: 'T.interés', value: nextPayment.interest ? `${nextPayment.interest.toFixed(2)} €` : 'N/A' },
      ];
    }

    this.dataSource.data = operation.installments.map((installment: any) => ({
      cuota: installment.period || 0,
      fechaCobro: installment.date || null,
      pPrI: installment.amortization || 0,
      pTinI: installment.interest || 0,
      pCaI: installment.insurance || 0,
      pCaZ: installment.openingCommission || 0,
      pTinDI: installment.tax || 0,
      pCdI: installment.outstandingCapital || 0,
      estadoCd: installment.state || 'Desconocido',
      totalPago: installment.payment || 0,
    }));
  }
  
  formatOperationId(operationId: number): string {
    return operationId.toString().padStart(6, '0');
  }

  formatDetailValue(value: any): string {
    if (!value) return '-';
    if (!isNaN(parseFloat(value)) && isFinite(value)) {
      return new Intl.NumberFormat('es-ES', {
        style: 'currency',
        currency: 'EUR',
        minimumFractionDigits: 2,
      }).format(value);
    }2
    return this.stateFinancing(value);
  }

  redirectToInstallmentDetails(element: any): void {
    const id = this.operation.codeOperation;
    const cuota = element.cuota;
  
    this.installmentService.setInstallment(element);
    this.router.navigate([`/contratacion/renting/${id}/cuotas/${cuota}`]);
  }

  getStatusClass(state: string): string {
    switch (state.toLowerCase()) {
      case 'approved':
        return 'status-aprobado';
      case 'denied':
        return 'status-denegado';
      case 'pending':
        return 'status-pendiente';
      case 'completed':
        return 'status-completado';
      case 'signed':
        return 'status-firmado';
      default:
        return '';
    }
  }
}
