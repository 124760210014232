    <form #beneficiary *ngIf="form&& favAccounts && transferType !== 'ownAccounts'" [formGroup]="form" >
      <p *ngIf="favAccounts.length > 0">A una cuenta favorita</p>
      <mat-form-field *ngIf="favAccounts.length > 0" appearance="outline" class="long_input">
        <mat-label>Cuentas guardadas</mat-label>
          <mat-select *ngIf="transferType !== 'international'" formControlName="favAccount" (selectionChange)="setInputs()">
            <mat-option class="delete-account" *ngFor="let item of favAccounts" [value]="item">
              <div class="option-content">
              <p>{{item.alias}}  {{toPanSecretResume(item.iban)}}</p>
            </div>
            </mat-option>
          </mat-select>
          <!-- <mat-select *ngIf="transferType === 'international'" formControlName="favAccount" (selectionChange)="setInputs()">
            <mat-option *ngFor="let item of exIban" [value]="item">
              {{item.alias}}  {{toPanSecretResume(item.iban)}}
            </mat-option>
          </mat-select> -->
        </mat-form-field>
        
        
        <button class="delete_icon" matTooltip="Cancelar" [hidden]="!form.get('favAccount')?.value" (click)="cancel()" aria-label="cancel">
          <mat-icon >cancel</mat-icon>
        </button>
        <button (click)="openModal(form.get('favAccount')?.value)" class="cancel_icon" matTooltip="Eliminar" [hidden]="!form.get('favAccount')?.value" aria-label="cancel">
          <mat-icon>delete</mat-icon>
        </button>
        <div class="column-inputs">
          <p [hidden]="form.get('favAccount')?.value">Nueva cuenta</p>
      <mat-form-field appearance="outline" class="long_input">
        <mat-label>Nombre y apellidos</mat-label>
        <input  matInput formControlName="beneficiaryNameDis" *ngIf="disabled">
        <input matInput formControlName="beneficiaryName" *ngIf="!disabled">
        <mat-error *ngIf="!form.get('beneficiaryName')?.valid">Introduce un nombre</mat-error>
      </mat-form-field>
      <mat-form-field  appearance="outline" class="iban_input" >
        <mat-label>Cuenta abono (IBAN)</mat-label>
        <input  matInput formControlName="beneficiaryAccountDis" *ngIf="disabled">
        <input  matInput formControlName="beneficiaryAccount" *ngIf="!disabled">
        <mat-error *ngIf="!form.get('beneficiaryAccount')?.valid">Introduce un número de cuenta válido</mat-error>
      </mat-form-field>
        <!-- <mat-form-field class="long_input"  appearance="outline" >
          <mat-label  >Otro tipo de cuentas</mat-label>
          <input  matInput formControlName="otherAccount">
          <mat-error *ngIf="!form.get('otherAccount')?.valid" >Introduce un número de cuenta válido</mat-error>
        </mat-form-field> -->
        <mat-form-field *ngIf="transferType == 'international'" class="long_input"  appearance="outline" >
          <mat-label >Código BIC/Swift (Banco beneficiario)</mat-label>
          <input  matInput formControlName="swiftBIC">
          <mat-error *ngIf="!form.get('swiftBIC')?.valid">Introduce un número de cuenta válido</mat-error>
        </mat-form-field>

      <button [disabled]="disabled" *ngIf="!alias" (click)="showAlias()" #tooltip="matTooltip"
        matTooltip="Añadir esta cuenta a favoritos" color="primary" mat-icon-button aria-label="añadir a favoritos">
          <mat-icon>save</mat-icon>
        </button>
        
        <button *ngIf="alias" (click)="hideAlias()" color="primary" mat-icon-button aria-label="cancelar">
          <mat-icon>cancel</mat-icon>
        </button>
      </div>
      <div class="alias">
        <mat-form-field *ngIf="alias" appearance="outline" class="long_input">
          <mat-label>Introduce un alias</mat-label>
          <input matInput formControlName="alias">
          <mat-error *ngIf="!form.get('alias')?.valid">Introduce un alias</mat-error>
        </mat-form-field>
          <button (click)="createFavAccount()" [disabled]="!form.valid" *ngIf="alias" class="form_button" mat-flat-button color="primary">Guardar en favoritos</button>
        </div>
      <div class="form_button">
       
      </div>

      <app-alerts *ngIf="isAlertOpen" (alertClosed)="handleAlertClosed($event)" [alertStatus]="alertStatus"[alertMessage]="alertMessage"></app-alerts>
     
    </form>
    
