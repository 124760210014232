<form class="form" #operation [formGroup]="form" *ngIf="userData">
  <mat-form-field appearance="outline" class="long_input" color="primary">
    <mat-label>Cuenta de origen</mat-label>
    <mat-select formControlName="originAccount" >
      <mat-option *ngFor="let item of accounts" [value]="item.id">
        Cuenta {{item.alias}}: {{toPanSecretResume(item.iban!)}}
      </mat-option>
    </mat-select>
    <mat-error *ngIf="!form.get('originAccount')?.valid && (form.get('originAccount')?.dirty)">
      Selecciona una cuenta de origen
    </mat-error>     
  </mat-form-field>
  <mat-form-field appearance="outline" class="short_input">
    <mat-label>Importe</mat-label>
    <input matInput formControlName="amount" [value]="formattedAmount" (change)="applyRounding()" (keypress)="preventLetters($event);twoDigitsAfterComma($event)" >
      <mat-error *ngIf="!form.get('amount')?.valid && (form.get('amount')?.dirty)">
        Introduce una cantidad correcta
      </mat-error> 
  </mat-form-field>
  <mat-form-field appearance="outline" *ngIf="transferType === 'international'">
    <mat-label>Moneda</mat-label>
    <mat-select name="currency" formControlName="currency">
      <mat-option *ngFor="let item of coin" [value]="item.value">{{item.value}} - {{item.viewValue}}</mat-option>
    </mat-select>
  </mat-form-field>
  <mat-form-field appearance="outline" class="medium_input">
    <mat-label>Concepto de la operación</mat-label>
    <input matInput formControlName="concept">
    <mat-error *ngIf="!form.get('concept')?.valid && (form.get('concept')?.dirty)">
      Introduce un concepto válido. (mínimo 3 caracteres)
    </mat-error> 
  </mat-form-field>
  <mat-form-field appearance="outline" *ngIf="transferType === 'ownAccounts'">
    <mat-label>Cuenta de destino</mat-label>
    <mat-select formControlName="destinyAccount">
      <mat-option *ngFor="let item of destinyAccountsList" [value]="item">
        {{item.alias}}: {{toPanSecretResume(item.iban!)}}
      </mat-option>
    </mat-select>
  </mat-form-field>
</form>
<div class="skeleton_container" *ngIf="!userData">
  <div class="inputs-skeleton">
    <ngx-skeleton-loader  count="1" appearance="line" [theme]="{ height: '57px'}">
    </ngx-skeleton-loader>
  </div>
  <div class="inputs-skeleton">
    <ngx-skeleton-loader count="1" appearance="line" [theme]="{ height: '57px'}">
    </ngx-skeleton-loader>
  </div>
  <div class="inputs-skeleton">
    <ngx-skeleton-loader  count="1" appearance="line" [theme]="{ height: '57px'}">
    </ngx-skeleton-loader>
  </div>
</div>
  <div>
    <button matStepperNext *ngIf="transferType !== 'ownAccounts'" [disabled]="!form.valid"
      (click)="onSubmit()" class="gold-button" matRipple>Siguiente</button>
  </div>



  


