<div class="all-searchs">
    <div class="desplegable">
        <mat-accordion>
            <mat-expansion-panel class="mat-elevation-z0">
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        Filtrar empresas
                    </mat-panel-title>
                    <mat-panel-description>
                        <mat-icon>filter_alt</mat-icon>
                    </mat-panel-description>
                </mat-expansion-panel-header>

                    <form [formGroup]="filterForm">
                        <div class="form_container">
                        <div class="range_container">
                            <span class="input-hint">Fecha de creación:</span>
                            <div class="inputs">
                                <mat-form-field appearance="outline" color="primary" class="input-medium">
                                    <mat-label>Desde</mat-label>
                                    <input formControlName="createdFrom" matInput [matDatepicker]="createdFrom" [max]="maxDate"/>
                                    <mat-datepicker-toggle matIconSuffix [for]="createdFrom"></mat-datepicker-toggle>
                                    <mat-datepicker #createdFrom></mat-datepicker>
                                </mat-form-field>
                                <mat-form-field appearance="outline" color="primary" class="input-medium">
                                    <mat-label>Hasta</mat-label>
                                    <input formControlName="createdTo" matInput [matDatepicker]="createdTo" [max]="maxDate"/>
                                    <mat-datepicker-toggle matIconSuffix [for]="createdTo"></mat-datepicker-toggle>
                                    <mat-datepicker #createdTo></mat-datepicker>
                                </mat-form-field>
                            </div>
                            <div>
                            </div>
                        </div>
                        <div class="range_container">
                            <span class="input-hint">CNAE</span>
                            <div class="inputs">
                              <mat-form-field appearance="outline" class="long_input">
                                <mat-label>CNAE</mat-label>
                                <input
                                  [formControl]="cnaeControl"
                                  #cnaeInput
                                  type="text"
                                  placeholder="Selecciona o escribe el CNAE"
                                  matInput
                                  [matAutocomplete]="auto"
                                  (input)="filterCnae()"
                                  (focus)="focusCnae()"
                                  (blur)="validateCnaeInput()">
                                <mat-icon matSuffix class="arrow-icon">arrow_drop_down</mat-icon>
                                <mat-autocomplete
                                  #auto="matAutocomplete"
                                  [displayWith]="displayFn"
                                  (optionSelected)="setCnae($event.option.value)">
                                  <mat-option *ngFor="let option of filteredOptions" [value]="option">
                                    {{option.code}} - {{option.name}}
                                  </mat-option>
                                </mat-autocomplete>
                                <mat-error *ngIf="!cnaeControl.valid">Debe seleccionar un CNAE</mat-error>
                              </mat-form-field>
                            </div>
                        </div>
                    </div>
                </form>

                <button class="gold-button" (click)="resetAll()">Borrar filtros</button>
            </mat-expansion-panel>
        </mat-accordion>
    </div>
</div>
