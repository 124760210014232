import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { TransfersAbstractService } from './transfers.abstract-service';

import { environment } from 'src/environments/environment';
import { Transfers } from '../../models/transfers/transfers';

@Injectable({
  providedIn: 'root'
})
export class TransfersService implements TransfersAbstractService{

  public transferData!: Transfers;

  constructor(private http: HttpClient) { }
  
  postTransfer(body:Transfers): Observable<Transfers> {
    return this.http.post<Transfers>(`${environment.coreUrl}/transfer`, body)
  }

  confirmSca(body: any): Observable<any> {
    return this.http.post<Transfers>(`${environment.coreUrl}/transfer/confirm`, body)
  }

}
