<div class="operations-container">
  <section>
    <section class="info-container">
      <h2>Ref. Mandato: <span>MNDT{{this.operation.codeOperation}}</span></h2>
      <div class="date-info-container">
        <h2>Fecha Primer Cobro:</h2>
        <mat-form-field appearance="outline" color="primary" class="input-medium">
          <mat-label>Fecha:</mat-label>
          <input matInput/>
          <mat-datepicker-toggle matIconSuffix></mat-datepicker-toggle>
          <mat-datepicker></mat-datepicker>
      </mat-form-field>
      </div>
    </section>
 </section>

  <section>
    <section class="details-container">
      <div class="detail-box" *ngFor="let detail of details">
        <div class="detail-header">{{ detail.label }}</div>
        <div class="detail-value">{{ formatDetailValue(detail.value) }}</div>
      </div>
    </section>

    <section class="table-container" *ngIf="!loader">
      <table mat-table [dataSource]="dataSource" matSort class="mat-table">

        <ng-container matColumnDef="Cuota">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Cuota</th>
          <td mat-cell class="clickable" *matCellDef="let element">
            <p class="gold">{{ element.cuota == 0 ? 'INI' : element.cuota }}</p>
          </td>
        </ng-container>    

        <ng-container matColumnDef="Fecha cobro">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Fecha cobro</th>
          <td mat-cell *matCellDef="let element">
            <p>{{ element.fechaCobro ? (element.fechaCobro | date: 'dd/MM/yyyy') : 'Pendiente de definir' }}</p>
          </td>
        </ng-container>

        <ng-container matColumnDef="Principal">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Principal</th>
          <td mat-cell *matCellDef="let element">
            <p>{{ element.pPrI | currency: '€':'symbol':'1.2-2':'es' }}</p>
          </td>
        </ng-container>

        <ng-container matColumnDef="Intereses">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Intereses</th>
          <td mat-cell *matCellDef="let element">
            <p>{{ element.pTinI | currency: '€':'symbol':'1.2-2':'es' }}</p>
          </td>
        </ng-container>


        <ng-container matColumnDef="Mensualidad">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Mensualidad</th>
          <td mat-cell *matCellDef="let element">
            <p>{{ (element.pPrI + element.pTinI + element.pCaI + element.pCaZ) | currency: '€':'symbol':'1.2-2':'es' }}</p>
          </td>
        </ng-container>

        <ng-container matColumnDef="Tasas">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Tasas</th>
          <td mat-cell *matCellDef="let element">
            <p>{{ element.pTinDI | currency: '€':'symbol':'1.2-2':'es' }}</p>
          </td>
        </ng-container>

        <ng-container matColumnDef="R.A pagar">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>R.A pagar</th>
          <td mat-cell *matCellDef="let element">
            <p>{{ element.pCdI | currency: '€':'symbol':'1.2-2':'es' }}</p>
          </td>
        </ng-container>

        <ng-container matColumnDef="Total a pagar">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Total a pagar</th>
          <td mat-cell *matCellDef="let element">
            <p>{{ (element.pPrI + element.pTinI + element.pCaI + element.pCaZ + element.pTinDI + element.pCdI) | currency: '€':'symbol':'1.2-2':'es' }}</p>
          </td>
        </ng-container>

        <ng-container matColumnDef="Estado">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Estado</th>
          <td mat-cell *matCellDef="let element">
            <p [ngClass]="{
                  'status-pendiente': element.estadoCd === 'PENDING',
                  'status-completado': element.estadoCd === 'COMPLETED',
                  'status-cancelado': element.estadoCd === 'CANCELLED'
                }">
              {{ stateFinancing(element.estadoCd) }}
            </p>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let element; columns: displayedColumns;" 
        (click)="redirectToInstallmentDetails(element)">
    </tr>
      </table>
    </section>

    <div class="no-data-message" *ngIf="!dataSource || dataSource.data.length === 0">
      <p>No hay datos disponibles para las amortizaciones.</p>
    </div>
  </section>

  <div class="skeleton-container" *ngIf="loader">
    <ngx-skeleton-loader count="{{ offset - 1 }}" appearance="line" [theme]="{ height: '76px' }">
    </ngx-skeleton-loader>
  </div>
</div>
