import { BeneficiaryData, OperationData, Transfers } from 'src/app/core/models/transfers/transfers';
import { Component, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatStepper } from '@angular/material/stepper';
import { StepperSelectionEvent } from '@angular/cdk/stepper';
import { OperationDataComponent } from '../components/operation-data/operation-data.component';
import { BeneficiaryDataComponent } from '../components/beneficiary-data/beneficiary-data.component';


@Component({
  selector: 'app-national-transfers',
  templateUrl: './national-transfers.component.html',
  styleUrls: ['./national-transfers.component.scss']
})
export class NationalTransfersComponent {

  public beneficiaryData?: BeneficiaryData;
  public validBeneficiary?: boolean;
  public ibanBeneficiary!: string;
  public amountRounded!: any;

  public operationData!: OperationData;
  public validOperationData?: boolean
  public originAccount!: string

  public _showConfirm!: boolean;
  public transferData!: Transfers;
  public isLoading!: boolean;

  @ViewChild('stepper') stepper!: MatStepper;
  @ViewChild(OperationDataComponent) operation!: OperationDataComponent;
  @ViewChild(BeneficiaryDataComponent) beneficiary!: BeneficiaryDataComponent;

  constructor() {}

  receiveBeneficiaryData(form: FormGroup) {
    this.beneficiaryData = form.value;
    this.validBeneficiary = form.valid;
  }
  receiveOperationData(form: FormGroup) {
    this.operationData = form.value;
    this.validOperationData = form.valid;
    this.originAccount = this.operationData?.originAccount
    this.toNumberRounded(this.operationData.amount)
  }

  onSubmit() {
    this.buildTransferData();
    this.stepper.next();
  }

  //Función para crear el objeto de transferData
  buildTransferData() {
    if (this.operationData && this.beneficiaryData) {
      this.transferData = {
        sender: {
          name: "Denarius Finance",
          accountId: this.operationData.originAccount,
        },
        receiver: {
          name: this.beneficiaryData.beneficiaryName,
        },
        concept: this.operationData.concept,
        amount: this.amountRounded,
      };

      if (this.beneficiaryData.favAccount !== null) {
        console.log("Es cuenta favorita", this.transferData)
        this.transferData.receiver.token = this.beneficiaryData.favAccount.token;
        this.transferData.receiver.tokenType = this.beneficiaryData.favAccount.tokenType;
        this.transferData.type = 'TOKEN';
        this.ibanBeneficiary = this.beneficiaryData.favAccount.iban!;
      } else {
        console.log("NO es cuenta favorita", this.transferData)
        this.transferData.receiver.iban = this.beneficiaryData.beneficiaryAccount;
        this.transferData.type = 'SEPA';
        this.ibanBeneficiary = this.beneficiaryData.beneficiaryAccount;
      }
    } else {
      console.error('Missing beneficiaryData or operationData');
    }
  }

  toNumberRounded(value: any) {
    this.amountRounded = value.replace(/\./g, '').replace(',', '.');
    this.amountRounded = Number(parseFloat(this.amountRounded).toFixed(2)); 
  }
  
  //Función para que cada vez que se cambie de step se actualice el objeto de transferData
  stepChange(event: StepperSelectionEvent){
    this.buildTransferData()
  }

  resetTransfers() {   
   this.operation.form.reset()
   this.beneficiary.form.reset()
   this.stepper.reset();
   window.scrollTo(0, 0);
  }
}

