import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { BeneficiaryData, OperationData, Transfers } from 'src/app/core/models/transfers/transfers';
import { NotificationData, TypeData } from '../../../core/models/transfers/transfers';
import { FormGroup } from '@angular/forms';
import { MatStepper } from '@angular/material/stepper';
import { OperationDataComponent } from '../components/operation-data/operation-data.component';

@Component({
  selector: 'app-own-accounts-transfers',
  templateUrl: './own-accounts-transfers.component.html',
  styleUrls: ['./own-accounts-transfers.component.scss']
})
export class OwnAccountsTransfersComponent {

  public beneficiaryData?: BeneficiaryData;
  public validBeneficiary?: boolean;

  public operationData?: OperationData;
  public amountRounded!: any;
  public validOperationData?: boolean;
  public ibanBeneficiary?: string;

  public _showConfirm!: boolean;
  public transferData!: Transfers;
  public isLoading!: boolean;

  public noBeneficiary: boolean = false;

  @ViewChild('stepper') stepper!: MatStepper;
  @ViewChild(OperationDataComponent) operation!: OperationDataComponent;

  constructor() { }


  receiveOperationData(form: FormGroup) {
    this.operationData = form.value;
    this.validOperationData = form.valid;
    this.toNumberRounded(this.operationData?.amount);
  }

  onSubmit() {
    this.buildTransferData();
    this.stepper.next();
  }

  buildTransferData() {
    if(this.operationData){
      this.transferData = {
        sender: {
          name: "Denarius Finance",
          accountId: this.operationData?.originAccount,
        },
        receiver: {
          accountId: this.operationData!.destinyAccount?.id,
          token: this.operationData!.destinyAccount?.token
        },
        type: "INTERNAL",
        concept: this.operationData?.concept,
        amount:  this.amountRounded,
      };
  
      this.ibanBeneficiary = this.operationData!.destinyAccount!.iban
    }
    }

    toNumberRounded(value: any) {
    this.amountRounded = value.replace(/\./g, '').replace(',', '.');
    this.amountRounded = Number(this.amountRounded); 
  }


  resetTransfers() {   
    this.operation.form.reset()
    this.stepper.reset();
    window.scrollTo(0, 0);
   }

}
