<div class="signature">
    <h2>Documentos</h2>
    <div class="button">
      <button class="gold-button" (click)="openUploadModal()">+ Subir Documento</button>
    </div>
    <div class="tableMaterial">
      <ng-container *ngIf="!loader">
        <table *ngIf="documents.length > 0" mat-table [dataSource]="tableData" class="table-container" matSort>
          <ng-container matColumnDef="view">
            <th mat-header-cell *matHeaderCellDef>Ver</th>
            <td mat-cell *matCellDef="let element">
              <div class="icon-link">
                <button mat-icon-button (click)="openViewDocumentModal(element)">
                  <mat-icon style="cursor:pointer">visibility</mat-icon>
                </button>
              </div>
            </td>
          </ng-container>
          <ng-container matColumnDef="documentos">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Documentos</th>
            <td mat-cell *matCellDef="let element" [matTooltip]="element.documentos.length > 50 ? element.documentos : null" matTooltipShowDelay="1000">{{ element.documentos | shorten:50 }}</td>
          </ng-container>
          <ng-container matColumnDef="description">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Descripción</th>
            <td mat-cell *matCellDef="let element">{{ element.comment || 'N/A' }}</td>
          </ng-container>
          <ng-container matColumnDef="date">
            <th mat-header-cell *matHeaderCellDef>Fecha</th>
            <td mat-cell *matCellDef="let element">{{ element.date | date: 'dd/MM/yyyy' }}</td>
          </ng-container>
          <ng-container matColumnDef="send">
            <th mat-header-cell *matHeaderCellDef>Enviar</th>
            <td mat-cell *matCellDef="let element"><button mat-button (click)="openSendDocumentModal(element)">Enviar</button></td>
          </ng-container>
          <ng-container matColumnDef="delete">
            <th mat-header-cell *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let element"><button class="delete-button" (click)="confirmDeleteDocument(element.id)">Eliminar</button></td>
          </ng-container>
          <ng-container matColumnDef="cancel">
            <th mat-header-cell *matHeaderCellDef>Cancelar</th>
            <td mat-cell *matCellDef="let element"><button mat-button>Cancelar</button></td>
          </ng-container>
          
          <tr mat-header-row *matHeaderRowDef="displayedColumnsDocuments"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumnsDocuments;"></tr>
        </table>
  
        <div *ngIf="documents.length === 0" class="empty-message">
          <p>No hay documentos disponibles</p>
        </div>
      </ng-container>
  
      <div class="skeleton-container" *ngIf="loader">
        <ngx-skeleton-loader count="{{ offset - 1 }}" appearance="line" [theme]="{ height: '36px' }"></ngx-skeleton-loader>
      </div>
    </div>
  </div>
  