<div class="section_header">
  <p class="section_title">Transferencias</p>
</div>

<div class="page_container">
  <section class="page_content">
    <div class="all-transfers">
      <mat-tab-group animationDuration="0.5s" class="mat-tab">
        <mat-tab label="Nacionales y europeas" labelClass="mat-label">
          <div class="example-large-box mat-elevation-z4">
            <app-national-transfers></app-national-transfers>
          </div>
        </mat-tab>
        @if(singleAccount){
          <mat-tab label="A mis cuentas">
            <div class="example-large-box mat-elevation-z4">
              <app-own-accounts-transfers></app-own-accounts-transfers>
            </div>
          </mat-tab>
        }
<!--          <mat-tab label="A otros países">
            <div class="example-large-box mat-elevation-z4">
              <app-international-transfers (transfersInter)="transfersInter($event)"></app-international-transfers>
            </div>
          </mat-tab> -->
          <mat-tab label="Consultar transferencias">
            <div class="consult">
              <app-consult-transfers></app-consult-transfers>
            </div>
          </mat-tab>
      </mat-tab-group>
    </div>
  </section>
</div>



<!-- Componente transferencias genérico


<div class="form_container">
  <div class="form_section-dni">
    <p class="title">Datos del emisor</p>
    <div class="inputs_container">
      <mat-form-field appearance="fill">
        <mat-label>Número de DNI/NIE</mat-label>
        <input
          matInput
          [formControl]="identifier"
          (keydown.enter)="searchIdentifier()"
        />
        <mat-error
          *ngIf="
            !identifier.valid &&
            identifier?.dirty &&
            identifier?.hasError('noAccounts')
          "
          >Este usuario no tiene cuentas</mat-error
        >
        <mat-error
          *ngIf="
            !identifier.valid &&
            identifier?.dirty &&
            identifier?.hasError('notFound')
          "
          >Este usuario no existe</mat-error
        >
        <mat-error
          *ngIf="
            !identifier.valid &&
            identifier?.dirty &&
            identifier?.hasError('invalid')
          "
          >Formato de DNI/NIE inválido</mat-error
        >
      </mat-form-field>
      <button class="gold-button" (click)="searchIdentifier()">
        <div class="search_button">
          <p>Buscar</p>
          <mat-progress-bar
            color="accent"
            mode="indeterminate"
            *ngIf="loader"
          ></mat-progress-bar>
          <span *ngIf="!loader"></span>
        </div>
      </button>
      <br />
    </div>
  </div>
  <form [formGroup]="transferForm" (submit)="onSubmit()">
    <div class="form_section">
      <div class="inputs_container" id="operation_data">
        <section *ngIf="showSenderData">
          <mat-form-field appearance="fill">
            <mat-label>Nombre del emisor</mat-label>
            <input matInput formControlName="senderName" />
            <mat-error
              *ngIf="
                !transferForm.get('senderName')?.valid &&
                !transferForm.get('senderName')?.dirty
              "
            >
              Introduce un nombre
            </mat-error>
          </mat-form-field>

          <mat-form-field appearance="fill" class="long_input">
            <mat-label>Cuenta de origen</mat-label>
            <mat-select formControlName="originAccount">
              <mat-option *ngFor="let item of accounts" [value]="item.id">
                {{ item.alias }}: {{ item.iban! }}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="!transferForm.get('originAccount')?.valid">
              selecciona una cuenta
            </mat-error>
          </mat-form-field>

          <mat-form-field appearance="fill" class="short_input">
            <mat-label>Importe</mat-label>
            <input
              matInput
              formControlName="amount"
              (keypress)="preventLetters($event)"
            />
            <mat-icon matSuffix>euro</mat-icon>
            <mat-error *ngIf="!transferForm.get('amount')?.valid">
              introduce una cantidad correcta
            </mat-error>
          </mat-form-field>
          <mat-form-field appearance="fill" class="long_input">
            <mat-label>Concepto</mat-label>
            <input matInput formControlName="concept" />
            <mat-error *ngIf="!transferForm.get('concept')?.valid">
              Introduce un concepto (mínimo 3 caracteres)
            </mat-error>
          </mat-form-field>
        </section>
      </div>
    </div>
    <div class="form_section">
      <p class="title">Datos de destino</p>
      <div class="inputs_container" id="beneficiary_data">
        <mat-form-field appearance="fill" class="long_input">
          <mat-label>Nombre del destinatario</mat-label>
          <input matInput formControlName="receiver" />
          <mat-error *ngIf="!transferForm.get('receiver')?.valid">
            Introduce un nombre
          </mat-error>
        </mat-form-field>
        <mat-form-field appearance="fill" class="long_input">
          <mat-label>Cuenta de destino</mat-label>
          <input matInput formControlName="destinationAccount" />
          <mat-error *ngIf="!transferForm.get('destinationAccount')?.valid">
            introduce una cuenta correcta
          </mat-error>
        </mat-form-field>
      </div>
    </div>
    <div class="button_container">
      <button class="gold-button" type="submit" [disabled]="!transferForm.valid">
        Realizar transferencia
      </button>
    </div>
  </form>
</div>
 -->