import { CurrencyPipe } from "@angular/common";

export interface PlanInfo {
  title: string,
  active: boolean,
  content: InfoContent[]
}
export interface InfoContent {
  emoji?: string,
  text: string
}

//MODELO DE DATOS PLANES
export interface Plans {
  id?: string;
  name: string
  description: string
  segmentId: string,
  monthlyPrice: number
  cards: Card[]
  accounts: Account[]
  transfers: Transfers[]
  withdrawals: Withdrawal[]
  support: Support[]
  virtualTPV: boolean
  insurances?: boolean
  maxCards?: number
  manager?: boolean
  created?: string
  modified?:string
  active?: boolean
}
export interface Card {
  type: CardType
  issueCost: number
  maintenanceCost: number
  free: number
}
export interface Account {
  type?: string
  totalFree: number
  paid: boolean
}
export interface Transfers {
  maxFree: number
  cost: number
}
export interface Withdrawal {
  type?: WithdrawalType
  maxFree?: number
  cost: number
}
export interface Support {
  type: SupportChannel
  days: number
}

export class Plan implements Plans { 
  id!:string
  name!: string
  description!: string;
  cards: Card[] = []
  currencyPipe = new CurrencyPipe('es-ES', 'EUR')
  segmentId!: string;
  monthlyPrice!: number;
  accounts: Account[] = [];
  transfers: Transfers[] = [];
  withdrawals: Withdrawal[] = [];
  support: Support[] = [];
  virtualTPV: boolean = false;
  insurances?: boolean | undefined;
  maxCards?: number | undefined;
  hiddenTable: boolean = false;
  manager? : boolean

  constructor(values: Object = {}) {
    Object.assign(this, values);
  }

 

}

export enum Segment {
  INDIVIDUAL = 'INDIVIDUAL',
  BUSINESS = 'BUSINESS',
  FREELANCE = 'FREELANCE'
}

export enum SupportChannel {
  MAILBOX,
  PHONE,
}

export enum CardType {
  PHYSICAL,
  VIRTUAL
}

export enum WithdrawalType {
  ATM,
  POSTAL
}

export interface TableRow {
  tableData: any;
  key: string;
  title: string;
  value: string | number | boolean;
}