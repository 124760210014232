import { AccountsList, UserData } from 'src/app/core/models/user/userData';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import {Clipboard} from '@angular/cdk/clipboard';
import { MatDialog } from '@angular/material/dialog';
import { FormControl } from '@angular/forms';
import { UserService } from 'src/app/core/services/user/user.service';
import { registerLocaleData } from '@angular/common';
import { AccountDetailComponent } from '../account-detail/account-detail.component';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-selector-accounts',
  templateUrl: './selector-accounts.component.html',
  styleUrls: ['./selector-accounts.component.scss']
})
export class SelectorAccountsComponent {
  public userData!: UserData;
  public visibleAmount : boolean = true;
  public opcionSeleccionado: string = '0';
  public _userAccounts!: AccountsList[]
  public selectedOption!: string;
  public accountSelected: FormControl = new FormControl('');
  public accountData!: AccountsList;
  public accountId!: string;
  public loader: boolean = true
  public selectedIban: string = '';
  public userId: string = environment.denariusId;
/* public userId = localStorage.getItem('userId') */

  @Input() set userAccounts (val: AccountsList[]){
    if (!val){
      return
    } else{
      this._userAccounts = val;
      this.selectedOption = this._userAccounts?.[0].id
      this.accountSelected.setValue(this.selectedOption)
      this.onSubmit()
    }
   
  }
  @Output() selectedAccount: EventEmitter<string> = new EventEmitter<string>();

  constructor( private userDataService: UserService, private dialog:MatDialog, private clipboard: Clipboard){ }

  ngOnInit(): void {
    
  }

  hideShowAmount() {
    this.visibleAmount = !this.visibleAmount
  }
  copy() {
    this.clipboard.copy(this.selectedIban);
  }

  onSubmit(){
    const accountId = this.accountSelected.value;
    this.userDataService.getAccount(this.userId!, accountId!).subscribe((res)=> {
      this.accountData = res
      console.log('accountData', this.accountData)
      this.loader = false
      this.selectedAccount.emit(accountId)
      const selectedItem = this._userAccounts.find(item => item.id === this.accountSelected.value);
      this.selectedIban = selectedItem?.iban || '';
    })
    
  }

  openDialog() {
    this.dialog.open(AccountDetailComponent, {data: {accountData: this.accountData}});

  }
}
