import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { Coin } from '../../models/selects/selects';

@Injectable({
  providedIn: 'root',
})
export class SelectCoinMockService {
  public selectList(): Observable<Coin[]> {
    const coinList = [
      { value: 'EUR', viewValue: 'Euro' },
      { value: 'USD', viewValue: 'Dólar estadounidense' },
      { value: 'GBP', viewValue: 'Libra esterlina' },
      { value: 'JPY', viewValue: 'Yen japonés' },
      { value: 'CAD', viewValue: 'Dólar canadiense' },
      { value: 'AUD', viewValue: 'Dólar australiano' },
      { value: 'CHF', viewValue: 'Franco suizo' },
      { value: 'CNY', viewValue: 'Yuan chino' },
      { value: 'NZD', viewValue: 'Dólar neozelandés' },
      { value: 'MXN', viewValue: 'Peso mexicano' },
      { value: 'BRL', viewValue: 'Real brasileño' },
      { value: 'RUB', viewValue: 'Rublo ruso' },
      { value: 'INR', viewValue: 'Rupia india' },
      { value: 'KRW', viewValue: 'Won surcoreano' },
      { value: 'ZAR', viewValue: 'Rand sudafricano' },
      { value: 'TRY', viewValue: 'Lira turca' },
      { value: 'ARS', viewValue: 'Peso argentino' },
      { value: 'SGD', viewValue: 'Dólar de Singapur' },
      { value: 'HKD', viewValue: 'Dólar de Hong Kong' },
      { value: 'SEK', viewValue: 'Corona sueca' },
    ];
    return of(coinList);
  }
}
