<div class="summary_container">
    <div class="header_summary">
        <p class="title_summary">Confirmar transferencia</p>
    </div>
    <div class="alert" *ngIf="showAlert"> <app-alerts [alertMessage]="alertMessage"
            [alertStatus]="alertStatus"></app-alerts></div>
    <div>       
        <div class="subtitle">
            <div class="buttons-summary" *ngIf="!showAlert">
                <button class="stroked-gold-button width" matRipple matStepperPrevious [disabled]="isLoading">Atrás</button>
                @if (!isLoading) {
                <button class="gold-button" matRipple (click)="postTransfer()">Confirmar</button>
                }
                @else {
                <button class="gold-button" matRipple><mat-spinner class="spinner"></mat-spinner></button>
                }
            </div>
            <div>
                <div class="buttons-summary" *ngIf="showAlert">
                    @if (alertStatus == 'ok') {
                    <button class="gold-button" matRipple routerLink="/equipo">Ir a inicio</button>
                    <button class="gold-button" matRipple (click)="resetStepper()">Nueva transferencia</button>
                    }
                    @else {
                    <button class="gold-button" matRipple routerLink="/transferencias"
                        *ngIf="this.alertMessage !== 'Has introducido un código incorrecto. Inténtalo de nuevo.'">Empezar
                        de nuevo</button>
                    <button class="gold-button" matRipple
                        *ngIf="this.alertMessage === 'Has introducido un código incorrecto. Inténtalo de nuevo.'"
                        (click)="openVerification()">Volver a introducir pin</button>
                    }
                </div>
            </div>
            <div class="summary_content">
                <div class="inner_text">
                    <p class="inner_title">IBAN del envío: </p>
                    <p class="inner_data">{{ibanBeneficiary}}</p>
                </div>
                <div *ngIf="!noBeneficiary" class="inner_text">
                    <p class="inner_title">Nombre del beneficiario</p>
                    <p class="inner_data">{{transferData.receiver.name}}</p>
                </div>
                <div class="inner_text">
                    <p class="inner_title">Cantidad</p>
                    <p class="inner_data">{{transferData.amount | currency: "€":"symbol":"1.2-2":"es" }}</p>
                </div>
                <div class="inner_text">
                    <p class="inner_title">Asunto</p>
                    <p class="inner_data">{{transferData.concept}}</p>
                </div>
            </div>
        </div>
    </div>
</div>
