<div [formGroup]="transfersForm" class="all-transfers">
<div class="accounts_selector">
  <app-select-account [accountData]="userAccounts" (numberAccount)="selectedAccount($event)">
  </app-select-account>
</div>
<div class="filters_container">
  <app-filter (filtersEvent)="receiveFilters($event)"></app-filter>
</div>
  
</div>

<div class="tableMaterial" *ngIf="!loader">
  <table mat-table [dataSource]="dataSource"  multiTemplateDataRows >
    <ng-container matColumnDef="date">
      <th mat-header-cell *matHeaderCellDef>
        Fecha
      </th>
      <td mat-cell *matCellDef="let element">
        {{ element.date | date : "dd/MM/YYYY hh:MM:ss" }}
      </td>
    </ng-container>

    <ng-container matColumnDef="description">
      <th mat-header-cell *matHeaderCellDef>
        Beneficiario
      </th>
      <td mat-cell *matCellDef="let element">{{ element.description }}</td>
    </ng-container>

    <ng-container matColumnDef="amount">
      <th mat-header-cell *matHeaderCellDef>
        Importe
      </th>
      <td
        mat-cell
        *matCellDef="let element"
        [ngStyle]="{ color: element.amount > 0 ? 'green' : 'red' }"
      >
        {{ element.amount | currency : "€" : "symbol" : "1.2-2" : "es" }}
      </td>
    </ng-container>
    <ng-container matColumnDef="download">
      <th mat-header-cell *matHeaderCellDef>Descargar</th>
      <td mat-cell *matCellDef="let element">
        <div
          *ngIf="element.operationNumber === 1"
          (click)="downloadMovement(element.id, element.operationNumber)"
        >
          <mat-icon>download</mat-icon>
        </div>
      </td>
    </ng-container>

    <tr
      mat-header-row
      *matHeaderRowDef="displayedColumns; sticky: true"
    ></tr>
    <tr
      mat-row
      *matRowDef="let row; columns: displayedColumns; let i = index"
    ></tr>
  </table>
</div>

<div class="noTransferMsg" *ngIf="accumulatedData.length == 0 && movementsData.length < offset && !loader">No hay transferencias</div>

<div class="skeleton-container" *ngIf="loader" >
  <ngx-skeleton-loader  count="{{offset + (accumulatedData.length)}}" appearance="line" [theme]="{ height: '36px' }">
  </ngx-skeleton-loader>
</div>
<!-- //Expandir más -->
<div class="expand_table">
  <button
    *ngIf="movementsData.length == offset"
    class="gold-button"
    (click)="moreMovements()"
  >
    Más movimientos
  </button>
</div>
<mat-hint *ngIf="!showNo && movementsData.length !== offset && accumulatedData.length !== 0" class="noTransferMsg">
  No hay más transferencias
</mat-hint>

