import { AccountsList } from './../../../../core/models/user/userData';
import { CurrencyPipe, registerLocaleData } from '@angular/common';
import localeEs from '@angular/common/locales/es'
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { containsNumbers, preventLetters, setCurrencyFormat, toPanSecretResume, twoDigitsAfterComma } from 'src/app/core/extensions/functions';
import { Coin } from 'src/app/core/models/selects/selects';
import { UserData } from 'src/app/core/models/user/userData';
import { SelectCoinMockService } from 'src/app/core/services/selects/coinSelect.mock-service';
import { CoreService } from 'src/app/core/services/userArea/core.service';
import { UserService } from 'src/app/core/services/userArea/user.service';
import { environment } from 'src/environments/environment';

registerLocaleData(localeEs, 'es')
@Component({
  selector: 'app-operation-data',
  templateUrl: './operation-data.component.html',
  styleUrls: ['./operation-data.component.scss']
})
export class OperationDataComponent implements OnInit {
toPanSecretResume = toPanSecretResume

public userData!: any;
public form!: FormGroup;
public coin!: Coin[]
public operationAmountInput! : string;
public formattedAmount!: any;
public amount!: any;
public accounts: any;

public destinyAccountsList?: AccountsList[]

public amountToNumber!: number;
public userId = environment.denariusId;
/* public userId: string = localStorage.getItem('userId')! */

setCurrencyFormat = setCurrencyFormat
preventLetters = preventLetters
twoDigitsAfterComma = twoDigitsAfterComma


@Output() operationDataEvent = new EventEmitter<FormGroup>();
@Input() transferType?:string;

constructor(private userDataService: CoreService, private formBuilder: FormBuilder, private currencyPipe : CurrencyPipe, private coinService: SelectCoinMockService){
}

ngOnInit(){
  console.log(this.userId)
  this.coinService.selectList().subscribe(
    data =>{
      this.coin = data
    }
  )

  this.form = this.formBuilder.group({
   originAccount: ['', [Validators.required]],
   amount: ['', [Validators.required]],
   concept: ['',[Validators.required, Validators.minLength(3)]],
  })

  if(this.transferType === 'international'){
    this.form.addControl('currency', new FormControl('', Validators.required))
  } else if(this.transferType === 'ownAccounts') {
    this.form.addControl('destinyAccount', new FormControl('', Validators.required))
  }

  this.userDataService.getAccounts(this.userId).subscribe(res => {
  this.accounts = res.filter(item => item.state === "ACTIVATED")
  this.userData = res;
  this.form.valueChanges.subscribe((val) => {
    this.destinyAccountsList = this.accounts.filter((item: any) => item.id !==   this.form.get('originAccount')?.value)
    this.operationDataEvent.emit(this.form);
  });
})
}

  // funcion para cambiar los para setear el formato que necesitamos
  applyRounding() {
    const actualValue = this.form.get('amount')?.value;
    if (actualValue) {
      this.formattedAmount = setCurrencyFormat(actualValue);
    }
  }

  onSubmit(){
    this.form.get('amount')?.setValue(this.formattedAmount)
  }
}
