
      <div class="modal-content">
        <div class="modal-body">
          <p>{{modalMessage}}</p>
<!--           <mat-form-field class="input" *ngIf="hasInput">
            <input matInput [(ngModel)]="inputValue">
          </mat-form-field> -->
        </div>
        <div class="modal-footer">
          <button type="button" class="stroked-gold-button" mat-dialog-close (click)="closeModal()">Cancelar</button>
          <button type="button" class="gold-button" (click)="customAction()">{{modalOption}}</button>
        </div>
      </div>

  
 