import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AccountsList, UserData } from '../../models/user/userData';
import { AccountCreate, AccountMovements, AccountTransfer } from '../../models/accounts/account-movements';
import { FavAccount } from '../../models/transfers/transfers';

@Injectable({
  providedIn: 'root'
})
export class CoreService {

  constructor( private http: HttpClient) { }

  public downloadMovement(userId: string, accountId: string, movementId: string, operationNumber: number): Observable<Blob> {
    let queryParam = new HttpParams()
    queryParam = queryParam.set("operationNumber", operationNumber);
    return this.http.get<any>(`${environment.coreUrl}/${userId}/accounts/${accountId}/movement/${movementId}/receipt`, { params: queryParam, responseType: 'blob' as 'json' },)
  };
  public getMovementsBusiness(userId: string | null, accountId: string | null): Observable<any> {
    return this.http.get<any>(`${environment.coreUrl}/${userId}/accounts/${accountId}/movements`)
  }
    //ONBOARDING
    public getDashboard() {
      return this.http.get<UserData>(`${environment.coreUrl}/users/dashboard`);
    }
    public getAccounts(userId: string) {
      return this.http.get<AccountsList[]>(`${environment.coreUrl}/${userId}/accounts`)
    }
    public createAccount(userId: string, body?: AccountCreate) {
      return this.http.post<AccountCreate>(`${environment.coreUrl}/${userId}/accounts`, body)
    }
    public getAccount(id: string) {
      return this.http.get<AccountsList>(`${environment.coreUrl}/users/accounts/${id}`)
    }
    public getMovements(accountId: string, params?: any) {
      return this.http.get<AccountMovements[]>(`${environment.coreUrl}/users/accounts/${accountId}/movements`, { params })
    }
    public getTransfers(accountId: string, params?: HttpParams) {
      return this.http.get<AccountMovements[]>(`${environment.coreUrl}/users/accounts/${accountId}/transfers`, { params })
    }
    public getTransferDetail(userId: string, id: string, transferId: string) {
      return this.http.get<AccountTransfer>(`${environment.coreUrl}/${userId}/accounts/${id}/transfers/${transferId}`)
    }
    public getMovementDetail(userId: string, id: string, movementId: string, operationNumber: number) {
      let queryParam = new HttpParams()
      queryParam = queryParam.set("operationNumber", operationNumber);
      return this.http.get<AccountMovements>(`${environment.coreUrl}/${userId}/accounts/${id}/movements/${movementId}`, { params: queryParam })
    }

    public createFavAccount(accountId: string, body: FavAccount) {
      return this.http.post<FavAccount>(`${environment.coreUrl}/users/accounts/${accountId}/inst`, body)
    }

    public getFavAccounts(accountId: string) {
      return this.http.get<FavAccount[]>(`${environment.coreUrl}/users/accounts/${accountId}/inst`)
    }

    public deleteFavAccount(accountId: string, favAccountId: string) {
      return this.http.delete<FavAccount>(`${environment.coreUrl}/users/accounts/${accountId}/inst/${favAccountId}`)
    }

    public confirmInst(operationPath: string, body?: any) {
      return this.http.post<any>(`${environment.coreUrl}${operationPath}`, body)
    }

    public getOwnershipCertificate(id: string, accountId: string): Observable<Blob> {
      return this.http.get(`${environment.coreUrl}/${id}/accounts/${accountId}/certificate/ownership`, {
        headers: new HttpHeaders({
          'Accept': 'application/pdf'
        }),
        responseType: 'blob'
      });
    }
    public getBalanceCertificate(id: string, accountId: string, params?: any): Observable<any> {
      return this.http.get(`${environment.coreUrl}/${id}/accounts/${accountId}/certificate/balance`);
    }
    public changeAccountAlias(userId: string, accountId: string, alias: string): Observable<any>{
      return this.http.post<any>(`${environment.coreUrl}/${userId}/accounts/${accountId}/alias`, {alias: alias})
    }
}
